import { type MutationTree, type ActionTree, type GetterTree } from 'vuex'
import { type AxiosInstance } from 'axios'
import axios from 'axios'

type LegalStructureOption = { text: string; value: string }
type FederationNamesOption = { text: string; value: string }

class State {
  address = {
    street: '',
    zipCode: '',
    city: ''
  }
  addresses = []
  appId = null
  conditions = false
  isAssociation = false
  email = ''
  errorEmail = false
  firstName = ''
  birthDate = null
  geoLocation = {}
  lastName = ''
  orgName = ''
  orgType = ''
  legalStructure = ''
  legalStructureOptions: LegalStructureOption[] = []
  orgTypes = [
    {
      id: 1,
      text: 'Politique',
      value: 'politics'
    },
    {
      id: 12,
      text: 'Défense des droits',
      value: 'rights'
    },
    {
      id: 38,
      text: 'Arts et culture',
      value: 'arts'
    },
    {
      id: 52,
      text: 'Club de loisirs et amicales',
      value: 'leisure'
    },
    {
      id: 69,
      text: 'Action sociale',
      value: 'social'
    },
    {
      id: 81,
      text: 'Préservation du patrimoine',
      value: 'heritage'
    },
    {
      id: 89,
      text: 'Sports',
      value: 'sports'
    },
    {
      id: 146,
      text: 'Éducation et formation',
      value: 'education'
    },
    {
      id: 155,
      text: 'Étudiante',
      value: 'student'
    },
    {
      id: 156,
      text: 'Anciens élèves',
      value: 'former-students'
    },
    {
      id: 163,
      text: 'Recherche et sciences',
      value: 'research'
    },
    {
      id: 193,
      text: 'Médico-sociale',
      value: 'health'
    },
    {
      id: 220,
      text: 'Humanitaire - Caritative',
      value: 'aid'
    },
    {
      id: 248,
      text: 'Professionnelle',
      value: 'professional'
    },
    {
      id: 257,
      text: 'Environnement',
      value: 'environment'
    },
    {
      id: 295,
      text: 'Religion et spiritualité',
      value: 'religion'
    }
  ]
  budget = ''
  budgets = [
    {
      text: 'Moins de 10 000 € par an',
      value: 'Below10000'
    },
    {
      text: 'De 10 000 € à 50 000 € par an',
      value: 'Between10000And50000'
    },
    {
      text: 'De 50 000 € à 200 000 € par an',
      value: 'Between50000And200000'
    },
    {
      text: 'Plus de 200 000 € par an',
      value: 'Above200000'
    }
  ]
  password = ''
  phone = ''
  typology = null
  referrer = null
  referrerInfo = null
  federationName = ''
  federationNameOptions: FederationNamesOption[] = []
  orgSlug = ''
}

export const getters: GetterTree<State, any> = {
  getOrganizationTypeById: (state: State) => (typeId: number) => {
    return state.orgTypes.find(({ id }) => id === typeId).value
  }
}

const state = () => new State()

const mutations = <MutationTree<State>>{
  SET_ORG_NAME(state: State, orgName: string) {
    state.orgName = orgName
  },
  SET_ORG_TYPE(state: State, orgType: string) {
    state.orgType = orgType
  },
  SET_LEGAL_STRUCTURE(state: State, legalStructure: string) {
    state.legalStructure = legalStructure
  },
  SET_LEGAL_STRUCTURE_OPTIONS(
    state: State,
    legalStructureOptions: LegalStructureOption[]
  ) {
    state.legalStructureOptions = legalStructureOptions
  },
  SET_FEDERATION_NAME(state: State, federationName: string) {
    state.federationName = federationName
  },
  SET_FEDERATION_NAME_OPTIONS(
    state: State,
    federationNameOptions: FederationNamesOption[]
  ) {
    state.federationNameOptions = federationNameOptions
  },
  SET_BUDGET(state: State, budget: string) {
    state.budget = budget
  },
  SET_STREET(state: State, street: string) {
    state.address.street = street
  },
  SET_ZIPCODE(state: State, zipCode: string) {
    state.address.zipCode = zipCode
  },
  SET_CITY(state: State, city: string) {
    state.address.city = city
  },
  SET_GEOLOCATION(state: State, geoLocation) {
    state.geoLocation = geoLocation
  },
  SET_FIRST_NAME(state: State, firstName: string) {
    state.firstName = firstName
  },
  SET_LAST_NAME(state: State, lastName: string) {
    state.lastName = lastName
  },
  SET_BIRTHDATE(state: State, birthDate) {
    state.birthDate = birthDate
  },
  SET_PHONE(state: State, phone: string) {
    state.phone = phone
  },
  SET_TYPOLOGY(state: State, typology) {
    state.typology = typology
  },
  SET_GOUV_ADDRESSES(state: State, addresses) {
    state.addresses = addresses
  },
  SET_EMAIL(state: State, email: string) {
    state.email = email
  },
  SET_PASSWORD(state: State, password: string) {
    state.password = password
  },
  SET_IS_ASSOCIATION(state: State) {
    state.isAssociation = !state.isAssociation
  },
  SET_CONDITIONS(state: State) {
    state.conditions = !state.conditions
  },
  SET_REFERRER(state: State, { referrer }) {
    state.referrer = referrer
  },
  SET_APP_ID(state: State, { appId }) {
    state.appId = appId
  },
  SET_ERROR_EXISTING_EMAIL(state: State, value) {
    state.errorEmail = value
  },
  SET_REFERRER_INFO(state: State, data) {
    state.referrerInfo = data
  },
  SET_ORGANIZATION_SLUG(state, organizationSlug) {
    state.orgSlug = organizationSlug
  }
}
const getActions = ({
  httpAuthProxy,
  httpApi
}: {
  httpAuthProxy: AxiosInstance
  httpApi: AxiosInstance
}): ActionTree<State, any> => ({
  /**
   * Fetch French government addresses
   * @param {*} commit
   * @param {string} query - search query
   */
  getGouvAddresses({ commit }, query: string) {
    // const searchQuery = replace(query, / /gm, '+') // old lodash check if below line ok
    const searchQuery = query.replace(/ /gm, '+')

    return axios
      .get(
        `https://api-adresse.data.gouv.fr/search/?q=${encodeURI(
          searchQuery
        )}&limit=5`
      )
      .then((response) => {
        const addresses = response?.data?.features || []
        commit('SET_GOUV_ADDRESSES', addresses)
        return addresses
      })
      .catch(console.error)
  },
  fetchManualAddress(
    { commit },
    {
      address,
      zipCode,
      city
    }: { address: string; zipCode: string; city: string }
  ) {
    const queryString = `${address} ${zipCode} ${city}`
    // const searchQuery = replace(queryString, / /gm, '+') // old lodash check if below line ok
    const searchQuery = queryString.replace(/ /gm, '+')

    return axios
      .get(
        `https://api-adresse.data.gouv.fr/search/?q=${encodeURI(
          searchQuery
        )}&limit=1`
      )
      .then((response) => {
        const {
          data: { features: addresses }
        } = response
        // Returned coordinates from datagouv API are inverted
        const [longitude, latitude] = addresses[0].geometry.coordinates
        // Update map coordinates
        commit('SET_GEOLOCATION', {
          latitude,
          longitude
        })
        return {
          address: addresses[0].properties.name,
          zipCode:
            addresses[0].properties.postcode ||
            addresses[0].properties.citycode,
          city: addresses[0].properties.city,
          coordinates: [latitude, longitude]
        }
      })
      .catch(console.error)
  },
  /**
   * Check if organization's name is already taken.
   * @param {string} orgName
   * @param {string} antiForgeryToken
   */
  verifyOrganizationName(
    _,
    { orgName, antiForgeryToken }: { orgName: string; antiForgeryToken: string }
  ) {
    const url = `/internal/auth-v2/verify-organization-name/${orgName}`
    return httpAuthProxy
      .get(url, {
        headers: {
          RequestVerificationToken: antiForgeryToken
        }
      })
      .then((response) => {
        return response
      })
  },
  /**
   * Create an organization for a logged user
   * @param {object} payload - The required organization's data
   */
  createOrganization({ commit }, { payload }) {
    return httpApi.post('/organizations', payload).then((response) => {
      commit('SET_ORGANIZATION_SLUG', response.organization.organizationSlug)
      return response
    })
  },
  /**
   * Create an organization as a new user
   * @param {object} payload - The required organization's data
   * @param {string} antiForgeryToken
   */
  registerOrganization({ commit }, { payload, antiForgeryToken }) {
    const url = '/internal/auth-v2/register-organization'
    return httpAuthProxy
      .post(url, payload, {
        headers: {
          RequestVerificationToken: antiForgeryToken
        }
      })
      .then((response) => {
        commit(
          'SET_ORGANIZATION_SLUG',
          response?.organization?.organizationSlug
        )
        return response
      })
  },
  /**
   * Create an organization as a new user
   * @param {object} payload - The required organization's data
   * @param {string} antiForgeryToken
   */
  enrollOrganization(_, { payload, antiForgeryToken }) {
    const url = '/internal/auth-v2/enroll-organization'
    return httpAuthProxy
      .post(url, payload, {
        headers: {
          RequestVerificationToken: antiForgeryToken
        }
      })
      .then((response) => response)
  },

  fetchReferrer({ state, commit }, { antiForgeryToken }) {
    const url = `/internal/auth-content-v2/${state.referrer}`
    return httpAuthProxy
      .get(url, {
        headers: {
          RequestVerificationToken: antiForgeryToken
        }
      })
      .then((response) => {
        commit('SET_REFERRER_INFO', response)
      })
  },
  fetchFederationNameOptions(
    { commit },
    { antiForgeryToken }: { antiForgeryToken: string }
  ) {
    return httpAuthProxy
      .get('/values/organization/federations', {
        headers: {
          RequestVerificationToken: antiForgeryToken
        }
      })
      .then((response) => {
        const federationNames = response?.map((item) => ({
          text: item,
          value: item
        }))
        commit('SET_FEDERATION_NAME_OPTIONS', federationNames)
      })
      .catch((error) => {
        throw error
      })
  },
  fetchLegalStructureOptions(
    { commit },
    { antiForgeryToken }: { antiForgeryToken: string }
  ) {
    return httpAuthProxy
      .get('/values/legal-structures', {
        headers: {
          RequestVerificationToken: antiForgeryToken
        }
      })
      .then((response) => {
        const legalStructures =
          response?.map((item) => ({
            text: item.label,
            value: item.id
          })) || []
        commit('SET_LEGAL_STRUCTURE_OPTIONS', legalStructures)
      })
      .catch((error) => {
        throw error
      })
  }
})

export default ({
  httpAuthProxy,
  httpApi
}: {
  httpAuthProxy: AxiosInstance
  httpApi: AxiosInstance
}) => ({
  state,
  mutations,
  getters,
  actions: getActions({ httpAuthProxy, httpApi }),
  namespaced: true
})
