import { defineNuxtPlugin } from '#imports'
import { haPlugin } from '@ha/components-v3'
import useConfigEnv from '@/composables/useConfigEnv'
import locales from '@/locales'

export default defineNuxtPlugin((nuxtApp) => {
  const {
    SEGMENT_KEY,
    SEGMENT_ENABLED,
    DD_ENV,
    DD_RUM_CLIENT_TOKEN,
    DD_RUM_SESSION_SAMPLE_RATE,
    DD_SERVICE,
    DD_SITE,
    DD_RUM_APP_ID,
    DD_RUM_SESSION_REPLAY_SAMPLE_RATE,
    DD_NUXT_ENV_TIMESTAMP
  } = useConfigEnv()

  const tracking = {
    SEGMENT_KEY,
    SEGMENT_ENABLED
  }
  const logger = {
    client: {
      DD_ENV,
      DD_RUM_CLIENT_TOKEN,
      DD_RUM_SESSION_SAMPLE_RATE,
      DD_SERVICE,
      DD_SITE
    },
    session: {
      DD_SERVICE,
      DD_RUM_APP_ID,
      DD_RUM_CLIENT_TOKEN,
      DD_SITE,
      DD_ENV,
      DD_RUM_SESSION_SAMPLE_RATE,
      DD_RUM_SESSION_REPLAY_SAMPLE_RATE,
      DD_NUXT_ENV_TIMESTAMP
    }
  }

  haPlugin(nuxtApp.vueApp, { locales, tracking, logger })
})
