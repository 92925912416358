import { type MutationTree, type ActionTree } from 'vuex'
import { type AxiosInstance } from 'axios'
import { getTokens, deleteCookies } from '@/cookies'

class State {
  antiForgeryToken: string = ''
  queryParams = {}
  missingQueryParams = []
  user = null
  userLoading = false
  organizations = []
  client = null
}

const state = () => new State()

const mutations = <MutationTree<State>>{
  SET_ANTI_FORGERY_TOKEN(state: State, token: string) {
    state.antiForgeryToken = token
  },
  SET_QUERY_PARAMS(state: State, params: object) {
    state.queryParams = params
  },
  SET_MISSING_QUERY_PARAMS(state: State, params) {
    state.missingQueryParams = params
  },
  SET_USER(state: State, user) {
    state.user = user
  },
  SET_USER_LOADING(state: State, loading: boolean) {
    state.userLoading = loading
  },
  SET_ORGANIZATIONS(state: State, organizations) {
    state.organizations = organizations
  },
  SET_CLIENT(state: State, client) {
    state.client = client
  }
}

const getActions = ({
  httpOAuth,
  httpAuthProxy,
  httpApi
}: {
  httpOAuth: AxiosInstance
  httpAuthProxy: AxiosInstance
  httpApi: AxiosInstance
}): ActionTree<State, any> => ({
  fetchAntiForgeryToken({ commit }) {
    return httpAuthProxy
      .get('/internal/auth-v2/antiforgerytoken')
      .then((response) => {
        const token = response
        commit('SET_ANTI_FORGERY_TOKEN', token)

        // Reset token after 45 minutes
        setTimeout(() => {
          commit('SET_ANTI_FORGERY_TOKEN', null)
        }, 45 * 60000)
        return token
      })
  },
  readQueryParams({ commit }) {
    // Parse URL query params
    const urlParams = new URLSearchParams(window.location.search)

    const query = {
      code_challenge: urlParams.get('code_challenge'),
      code_challenge_method: urlParams.get('code_challenge_method'),
      client_id: urlParams.get('client_id'),
      redirect_uri: urlParams.get('redirect_uri'),
      state: urlParams.get('state')
    }

    const validQueryParams = [
      { name: 'code_challenge', required: true },
      { name: 'code_challenge_method', required: true },
      { name: 'client_id', required: true },
      { name: 'redirect_uri', required: true },
      { name: 'state', required: false }
    ]

    // Handle missing required params
    const missingQueryParams = validQueryParams.filter((param) => {
      return param.required && !Object.keys(query).includes(param.name)
    })
    if (missingQueryParams.length) {
      commit('SET_MISSING_QUERY_PARAMS', missingQueryParams)
      return
    }
    // Fill store with valid params
    const filteredQueryParams = {}
    Object.keys(query).forEach((key) => {
      if (validQueryParams.map((param) => param.name).includes(key)) {
        filteredQueryParams[key] = query[key]
      }
    })
    commit('SET_QUERY_PARAMS', filteredQueryParams)
  },
  putUserInfo(_, payload) {
    return httpApi.put('/users/me', payload).then((data) => {
      return data
    })
  },
  refreshToken() {
    const { refreshToken } = getTokens()

    if (!refreshToken) {
      return Promise.reject(new Error('No available refresh token'))
    }

    // Build query string
    const queryParams = [
      { name: 'grant_type', value: 'refresh_token' },
      { name: 'scope', value: '' },
      { name: 'refresh_token', value: refreshToken }
    ]

    let queryParamsString = ''
    queryParams.forEach((p, index) => {
      queryParamsString += `${p.name}=${p.value}`
      if (index < queryParams.length - 1) {
        queryParamsString += '&'
      }
    })

    return httpOAuth.post('/token', queryParamsString, {
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }
    })
  },
  login(_, { email, password, antiForgeryToken }) {
    return httpAuthProxy.post(
      '/internal/auth-v2/login',
      { email, password },
      {
        headers: {
          RequestVerificationToken: antiForgeryToken
        }
      }
    )
  },

  fetchUser({ commit }) {
    commit('SET_USER_LOADING', true)
    return httpApi
      .get('agg/user')
      .then(({ user, organizations }) => {
        commit('SET_USER', user)
        commit('SET_ORGANIZATIONS', organizations)
        commit('SET_USER_LOADING', false)
      })
      .catch((error) => {
        throw error
      })
  },
  /**
   * Fetch a client
   * @param commit
   * @param {string} clientId - Client's ID
   */
  async fetchClient({ commit, state, dispatch }, { clientId }) {
    let query
    if (state.user) {
      query = httpApi.get(`/api-clients/${clientId}`)
    } else {
      if (!state.antiForgeryToken) {
        await dispatch('fetchAntiForgeryToken')
      }
      query = httpApi.get(`/internal/auth-v2/api-clients/${clientId}`, {
        headers: {
          RequestVerificationToken: state.antiForgeryToken
        }
      })
    }
    return query
      .then((response) => {
        const client = response
        commit('SET_CLIENT', client)
      })
      .catch((error) => {
        throw error
      })
  },
  /**
   * Disconnect current user
   * @param commit
   */
  disconnectUser({ commit }) {
    const { accessToken } = getTokens()
    return httpOAuth
      .get('/disconnect', {
        withCredentials: true, // the response will invalidate every auth cookies (old and new)
        headers: { Authorization: `Bearer ${accessToken}` }
      })
      .then(() => {
        commit('SET_USER', null)
        commit('SET_ORGANIZATIONS', [])
      })
      .catch((error) => {
        throw error
      })
      .finally(() => {
        deleteCookies()
      })
  }
})

export default ({
  httpOAuth,
  httpAuthProxy,
  httpApi
}: {
  httpOAuth: AxiosInstance
  httpAuthProxy: AxiosInstance
  httpApi: AxiosInstance
}) => ({
  state,
  mutations,
  actions: getActions({ httpOAuth, httpAuthProxy, httpApi }),
  namespaced: true
})
