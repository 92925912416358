import { type MutationTree, type ActionTree } from 'vuex'
import { type AxiosInstance } from 'axios'

class State {
  antiForgeryToken: string = ''
  oneTimeToken: string = ''
}

const state = () => new State()

const mutations = <MutationTree<State>>{
  SET_ANTI_FORGERY_TOKEN(state: State, token: string) {
    state.antiForgeryToken = token
  },
  SET_ONE_TIME_TOKEN(state: State, token: string) {
    state.oneTimeToken = token
  }
}

const getActions = ({
  httpAuthProxy
}: {
  httpAuthProxy: AxiosInstance
}): ActionTree<State, any> => ({
  /**
   * initialize the antiForgeryToken to allow next api calls
   * @param commit
   */
  fetchAntiForgeryToken({ commit }) {
    return httpAuthProxy
      .get('/internal/auth-v2/antiforgerytoken')
      .then((response) => {
        commit('SET_ANTI_FORGERY_TOKEN', response)
        return response
      })
  },
  /**
   * initialize a unique user session for the new payment
   * @param commit
   * @param oneTimeToken
   * @param antiForgeryToken
   */
  setOneTimeToken(
    { commit },
    {
      oneTimeToken,
      antiForgeryToken
    }: { oneTimeToken: string; antiForgeryToken: string }
  ) {
    return httpAuthProxy
      .post(
        `/internal/auth-v2/unverified-login?oneTimeToken=${oneTimeToken}`,
        { oneTimeToken },
        {
          headers: {
            RequestVerificationToken: antiForgeryToken
          }
        }
      )
      .then((response) => {
        commit('SET_ONE_TIME_TOKEN', oneTimeToken)
        return response
      })
  }
})

export default ({ httpAuthProxy }: { httpAuthProxy: AxiosInstance }) => ({
  state,
  mutations,
  actions: getActions({ httpAuthProxy }),
  namespaced: true
})
